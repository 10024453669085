import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ReactMarkdown from "react-markdown";
const TermsPage = () => {
  const { strapiTerms, strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiTerms {
        title
        text {
          data {
            childMarkdownRemark {
              rawMarkdownBody
              html
            }
          }
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);

  // console.log(strapiTerms);

  return (
    <Layout>
      <Seo seo={{ metaTitle: strapiTerms.title }} />
      <main>
        <section
          id="terms"
          className="ud-bg-primary ud-bg-opacity-[3%] ud-pt-[120px] ud-pb-[50px]"
        >
          <div className="ud-container">
            <div className="ud-mx-[-16px] ud-flex ud-flex-wrap ud-px-4">
              <div className="ud-w-full ud-px-4">
                <div
                  className="
                wow fadeInUp ud-mx-auto ud-mb-[100px]
                ud-max-w-[570px]
                ud-text-center
              "
                  data-wow-delay=".1s"
                >
                  <h2 className="ud-mb-4 ud-text-3xl ud-font-bold dark:ud-text-white sm:ud-text-4xl md:ud-text-[45px]">
                    {strapiTerms.title}
                  </h2>
                  <p
                    className="
                  ud-text-base ud-leading-relaxed
                  ud-text-body-color
                  md:ud-text-lg
                  md:ud-leading-relaxed
                "
                  ></p>
                </div>
              </div>
            </div>

            <div className="ud-mx-[-16px] ud-flex ud-flex-wrap">
              <div className="ud-w-full ud-px-4">
                <ReactMarkdown
                  className="ud-mb-8 ud-text-base ud-font-medium
  ud-leading-relaxed
  ud-text-body-color
  sm:ud-text-lg
  sm:ud-leading-relaxed
  lg:ud-text-base
  lg:ud-leading-relaxed
  xl:ud-text-lg xl:ud-leading-relaxed "
                  components={{
                    h1: ({ node, ...props }) => (
                      <h1
                        style={{
                          fontSize: 40,
                        }}
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <h2
                        style={{
                          fontSize: 28,
                        }}
                        {...props}
                      />
                    ),
                    h3: ({ node, ...props }) => (
                      <h3
                        style={{
                          fontSize: 24,
                        }}
                        {...props}
                      />
                    ),

                    p: ({ node, ...props }) => (
                      <p
                        style={{
                          margin: "0 0 1.15em 0 ",
                        }}
                        {...props}
                      />
                    ),

                    ul: ({ node, ...props }) => (
                      <ul
                        style={{
                          listStylePosition: "inside",
                          padding: 0,
                          listStyleType: "disc",
                        }}
                        {...props}
                      />
                    ),
                    ol: ({ node, ...props }) => (
                      <ol
                        style={{
                          listStylePosition: "inside",
                          padding: 0,
                          listStyleType: "decimal",
                        }}
                        {...props}
                      />
                    ),
                    blockquote: ({ node, ...props }) => (
                      <blockquote
                        style={{
                          borderRadius: 5,
                          textAlign: "center",
                          margin: "1.6em 0 1.6em 0",
                          padding: 10,
                        }}
                        {...props}
                      />
                    ),

                    a: ({ node, ...props }) => (
                      <a
                        target={"_blank"}
                        style={{
                          fontWeight: "bold",
                          borderBottom: "3px solid #FFE900",
                        }}
                        {...props}
                      />
                    ),
                  }}
                >
                  {strapiTerms.text.data.childMarkdownRemark.rawMarkdownBody}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default TermsPage;
